import React from 'react'
import _ from 'lodash'

import { ContainerCommonPanels, Modal } from 'ui/components'
import serviceStatusConstants from 'utils/constants/serviceStatus'
import { formatDate, formatDocNumber } from 'utils/masks'
import { translateRiskLevel } from 'utils/constants/riskLevel'
import TimeCounter from './TimeCounter'

export const renderCheckService = (service, theme) => {
  if (_.isEmpty(service) || _.isEmpty(_.get(service, 'data_id'))) {
    return <span style={{ color: theme.palette.primary.grayLight2 }}>Não solicitado</span>
  }

  if (_.get(service, 'status') === serviceStatusConstants.SERVICE_STATUS.processing) {
    if (_.get(service, 'slow_processing')) {
      const msgInfos = _.get(service, 'data.msg_infos')
      if (!_.isEmpty(msgInfos)) {
        return msgInfos.map(item => (
          <span style={{ color: theme.palette.primary.grayDark }} key={item.msg}>{item.msg}</span>
        ))
      }
    }

    return <TimeCounter responseTime={_.get(service, 'response_time')} />
  }

  const data = _.get(service, 'data')

  const msgErrors = _.get(data, 'msg_errors')
  if (!_.isEmpty(msgErrors)) {
    return msgErrors.map(item => <span key={item.msg}>{item.msg}</span>)
  }
  const msgInfos = _.get(data, 'msg_infos')
  if (!_.isEmpty(msgInfos)) {
    return msgInfos.map(item => <span key={item.msg}>{item.msg}</span>)
  }

  return null
}

export const ModalContainerPanels = ({
  docData,
  docType,
  onClose,
  open,
  withManualAnalysis,
  manualDerivationToRole,
  manualDerivationOnConfirm,
}) => {
  let title = [
    `${docType.toUpperCase()}: ${formatDocNumber(docData[docType], docType)}`,
    `Data: ${formatDate(docData.created_at)}`,
  ]
  if (!_.isEmpty(docData.risk_level)) {
    title.push(`Nível de Risco: ${translateRiskLevel(docData.risk_level)}`)
  }

  title = title.join(' | ')

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
    >
      <ContainerCommonPanels
        docId={docData.doc_id}
        docType={docType}
        withManualAnalysis={withManualAnalysis}
        manualDerivationToRole={manualDerivationToRole}
        manualDerivationOnConfirm={manualDerivationOnConfirm}
      />
    </Modal>
  )
}
