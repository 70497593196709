const RISK_LEVEL = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
}

const riskLevelName = {
  [RISK_LEVEL.LOW]: 'Baixo',
  [RISK_LEVEL.MEDIUM]: 'Médio',
  [RISK_LEVEL.HIGH]: 'Alto',
}

const translateRiskLevel = (key) => {
  return riskLevelName[key] || key
}

export {
  RISK_LEVEL,
  translateRiskLevel,
}
